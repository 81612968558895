import * as React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import Header from "./Header"
import Footer from "./Footer"
import "./layout.css"
import "./styles.css"

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const PageContent = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Layout = ({ children, ...props }) => {
  return (
    <PageWrapper>
      <Header />
      <PageContent>{children}</PageContent>
      <Footer originalPath={props.pageContext.originalPath} />
    </PageWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
