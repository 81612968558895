import * as React from "react"
import { ThemeProvider } from "@emotion/react"
import { QueryClientProvider, QueryClient } from "react-query"

import theme from "./theme"

const queryClient = new QueryClient()

const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </QueryClientProvider>
  )
}

export { wrapRootElement }
