import * as React from "react"
import styled from "@emotion/styled"
import { AiOutlineGlobal } from "@react-icons/all-files/ai/AiOutlineGlobal"

import { useLocalization } from "../i18n/hooks/use-localization"
import Link from "./Link"
import Wrapper from "./Wrapper"

const StyledFooter = styled.footer`
  margin-top: 2rem;
`

const Columns = styled.div`
  display: flex;
`

const Column = styled.div`
  width: 50%;
`

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.25em;
  padding: 0.75em 1em;
  border-radius: 0.5em;
  color: #000860;
  line-height: 1;
  text-decoration: none;
  overflow: hidden;
  transition: all 0.25s ease-out;

  &:hover {
    background-color: #f1f1fb;
  }
`

const LangLink = ({ to }) => {
  const path = to || "/"
  const { locale } = useLocalization()

  if (locale === "uk") {
    return (
      <StyledLink to={path} language="ru">
        <AiOutlineGlobal /> на русском
      </StyledLink>
    )
  } else {
    return (
      <StyledLink to={path} language="uk">
        <AiOutlineGlobal /> українською
      </StyledLink>
    )
  }
}

const Footer = ({ originalPath }) => {
  return (
    <StyledFooter>
      <Wrapper style={{ paddingTop: `0` }}>
        <hr />
        <Columns>
          <Column>© {new Date().getFullYear()}, ibud.ua</Column>
          <Column css={{ textAlign: "right" }}>
            <LangLink to={originalPath} />
          </Column>
        </Columns>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer
