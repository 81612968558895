import * as React from "react"
import styled from "@emotion/styled"
// import { Link } from "gatsby"
import { Trans } from "@lingui/macro"

import Logo from "./Logo"
// import PopupButton from "./PopupButton"
import Button from "./Button"

const Hero = styled.section`
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
  z-index: 9999;

  @media (max-width: 991px) {
    flex: 1;
  }
`

const Navbar = styled.header`
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding: 1.5em 5em;
  justify-content: space-between;
  z-index: 100;

  @media (max-width: 767px) {
    padding: 1.5em 1.5em 1em;
  }

  @media (max-width: 479px) {
    padding: 1em;
  }
`

const StyledLogo = styled(Logo)`
  width: 165px;
  object-fit: cover;
`

const Left = styled.div`
  gap: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Right = styled.div`
  gap: 2em;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 991px) {
    display: none;
  }
`

// const Links = styled("nav")({
//   gap: "1.5em",
//   display: "flex",
//   "align-items": "center",
//   "justify-content": "center",
//   "@media(max-width: 991px)": {
//     display: "none",
//   },
// })

// const StyledLink = styled(Link)({
//   cursor: "pointer",
//   "font-style": "normal",
//   "font-weight": "500",
//   "line-height": "24px",
//   transition: "0.3s",
//   "text-decoration": "none",
//   "&:hover": {
//     opacity: "0.5",
//   },
// })

// const Phone = styled("button")({
//   gap: "1.5em",
//   cursor: "pointer",
//   display: "flex",
//   "align-items": "center",
//   "border-width": "0px",
//   "flex-direction": "row",
//   "justify-content": "center",
// })

// const Image06 = styled("img")({
//   width: "18px",
//   "object-fit": "cover",
// })

// const Text06 = styled("span")({
//   "font-style": "normal",
//   "font-weight": "600",
//   "line-height": "24px",
// })

// const Book = styled("a")({
//   "text-decoration": "none",
// })

// const Image07 = styled("img")({
//   width: "18px",
//   "object-fit": "cover",
// })

// const Text07 = styled("span")({})

// const BurgerMenu = styled("div")({
//   display: "none",
//   "align-items": "center",
//   "justify-content": "center",
//   "@media(max-width: 991px)": {
//     display: "flex",
//     padding: "1em",
//     "border-radius": "4px",
//     "background-color": "#2461FF",
//   },
//   "@media(max-width: 767px)": {
//     display: "flex",
//   },
// })

// const Icon1 = styled("svg")({
//   width: "16px",
//   height: "16px",
//   "@media(max-width: 991px)": {
//     fill: "white",
//   },
// })

// const MobileMenu = styled("div")({
//   top: "0px",
//   left: "0px",
//   width: "100%",
//   height: "100vh",
//   "z-index": "1000",
//   // display: "none",
//   padding: "32px",
//   position: "fixed",
//   "flex-direction": "column",
//   "justify-content": "space-between",
//   "background-color": "#fff",
//   "@media(max-width: 479px)": {
//     padding: "16px",
//   },
// })

// const Nav = styled("div")({
//   display: "flex",
//   "align-items": "flex-start",
//   "flex-direction": "column",
// })

// const Container1 = styled("div")({
//   width: "100%",
//   display: "flex",
//   "align-items": "center",
//   "margin-bottom": "3em",
//   "justify-content": "space-between",
// })

// const Image08 = styled("img")({
//   height: "2rem",
// })

// const MenuClose = styled("div")({
//   display: "flex",
//   "align-items": "center",
//   "justify-content": "center",
// })

// const Icon3 = styled("svg")({
//   width: "16px",
//   height: "16px",
// })

// const Nav1 = styled("nav")({
//   flex: "0 0 auto",
//   display: "flex",
//   "align-items": "flex-start",
//   "flex-direction": "column",
// })

// const Text08 = styled("span")({
//   "margin-bottom": "1em",
// })

// const Text09 = styled("span")({
//   "margin-bottom": "1em",
// })

// const Text10 = styled("span")({
//   "margin-bottom": "1em",
// })

// const Text11 = styled("span")({
//   "margin-bottom": "1em",
// })

// const Book1 = styled("a")({
//   "text-decoration": "none",
// })

// const Image09 = styled("img")({
//   width: "18px",
//   "object-fit": "cover",
// })

// const Text12 = styled("span")({})

const Header = () => {
  return (
    <Hero>
      <Navbar>
        <Left>
          <StyledLogo />
          {/* <Links>
            <StyledLink href="#features">Features</StyledLink>
            <StyledLink href="#how-it-works">How it works</StyledLink>
            <StyledLink>Prices</StyledLink>
            <StyledLink href="#schedule">Contact</StyledLink>
          </Links> */}
        </Left>
        <Right>
          {/* <Button as="a" href={"https://chatbot.ibud.ua/lead"}>
            <Trans>Sign Up a company</Trans>
          </Button> */}
        </Right>
        {/* <BurgerMenu data-thq="thq-burger-menu">
          <Icon1 viewBox="0 0 1024 1024">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </Icon1>
        </BurgerMenu> */}
        {/* <MobileMenu data-thq="thq-mobile-menu">
          <Nav data-thq="thq-mobile-menu-nav" data-role="Nav">
            <Container1>
              <StyledLogo />
              <MenuClose data-thq="thq-close-menu">
                <Icon3 viewBox="0 0 1024 1024">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </Icon3>
              </MenuClose>
            </Container1>
            <Nav1 data-thq="thq-mobile-menu-nav-links" data-role="Nav">
              <Text08>Features</Text08>
              <Text09>How it works</Text09>
              <Text10>Prices</Text10>
              <Text11>Contact</Text11>
              <Button >Надіслати заявку</Button>
            </Nav1>
          </Nav>
        </MobileMenu> */}
      </Navbar>
    </Hero>
  )
}

export default Header
