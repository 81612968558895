import * as React from "react"

const Logo = () => (
  <svg
    width="165px"
    viewBox="0 0 960 176"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6507_18114)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M614.729 0.414062V58.7238C608.466 52.9746 601.446 48.4122 592.756 47.3309C586.597 46.5661 579.668 46.5661 571.199 46.5661C548.871 46.5661 533.473 62.3633 533.473 84.147V134.492C533.473 174.341 581.977 184.864 610.464 163.819C613.003 162.078 615.114 160.285 616.889 158.491L620.272 171.994H642.015V0.414062H614.729ZM611.865 80.0065L614.729 82.3009V135.547C611.715 138.316 607.855 141.217 604.201 143.169C602.63 144.013 601.096 144.672 599.684 145.094C585.442 149.076 560.034 151.107 560.034 134.571V83.4877C560.034 76.5517 563.422 72.042 570.352 72.042L591.139 72.2002C596.375 72.2794 601.302 73.7035 606.383 76.3407C608.26 77.4747 610.136 78.7143 611.865 80.0065Z"
        fill="#263238"
      />
      <path
        d="M671.331 148.944H698.184V175.95H671.331V148.944Z"
        fill="#263238"
      />
      <path
        d="M221.541 47.6737H248.394V175.95H221.541V47.6737Z"
        fill="#263238"
      />
      <path
        d="M221.541 0.414062H248.394V27.4196H221.541V0.414062Z"
        fill="#263238"
      />
      <path
        d="M429.653 47.6737V134.492C429.653 151.186 454.865 149.155 469.493 144.962C470.624 144.672 471.773 144.171 472.929 143.564C474.199 142.879 475.474 142.061 476.738 141.112C479.019 139.398 481.263 137.367 483.359 135.442V47.6737H510.212V175.95H490.072L486.586 158.175L479.657 163.819C450.399 184.864 402.8 174.341 402.8 134.492V47.6737H429.653Z"
        fill="#263238"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M297.652 160.39L295.387 175.95H268.534V0.414062H295.387L296.99 63.4182C304.095 56.1921 312.028 50.1 322.286 48.8078C326.29 48.333 330.35 48.1484 334.751 48.0957L343.613 48.0429C365.555 48.0429 380.954 63.8401 380.954 85.6239V135.995C380.954 175.818 332.218 186.341 303.5 165.295C301.227 163.687 299.299 162.052 297.652 160.39ZM297.544 85.1492C300.127 82.6438 303.739 80.0329 307.504 77.8176C312.431 75.1803 317.513 73.7562 322.594 73.6771L343.382 73.5452C347.017 73.5452 349.659 74.732 351.368 76.8154C352.991 78.7934 353.776 81.5889 353.776 84.9646V136.048C353.776 152.584 328.368 150.553 314.126 146.571C312.297 146.043 310.263 145.068 308.211 143.855C304.875 141.903 301.491 139.266 298.857 136.76L297.544 85.1492Z"
        fill="#263238"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M932.285 86.5469C932.285 57.9063 870.692 72.1739 859.144 79.3999V60.5963C888.4 40.685 960.002 38.0477 960.002 86.1513V175.95H939.862L934.595 161.762C933.056 163.713 930.746 165.744 927.667 167.247C902.939 182.701 855.946 179.326 850.675 143.96C849.135 131.618 851.446 117.35 860.685 108.331C875.313 94.0367 906.878 94.2741 932.285 97.0432V86.5469ZM876.653 137.762C877.132 141.165 878.241 144.197 879.931 145.859C889.941 156.54 922.584 150.026 931.823 141.244V117.192C927.744 116.453 910.034 115.319 898.486 115.689C887.554 116.453 878.315 119.46 876.775 129.218C876.289 131.934 876.258 134.967 876.653 137.762Z"
        fill="#263238"
      />
      <path
        d="M745.197 135.916V47.6737H718.324V135.916C718.324 175.739 769.065 186.341 797.551 165.295L797.993 164.953C800.154 163.212 802.27 161.471 803.71 159.652L805.597 175.95H825.737V47.6737H798.883V135.442C795.633 138.237 792.485 141.903 789.294 144.276C787.951 145.279 786.601 146.043 785.232 146.439C770.604 150.553 745.197 152.584 745.197 135.916Z"
        fill="#263238"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.398434L174.545 0.393799V175.93H0V0.398434Z"
        fill="#315EFB"
      />
      <path
        d="M127.551 47.6523H107.411V61.1551L87.2713 51.028L40.2783 74.6579V94.912L87.2713 71.2822L134.264 94.912V74.6579L127.551 71.2822V47.6523Z"
        fill="white"
      />
      <path d="M134.264 135.42V115.166H40.2783V135.42H134.264Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_6507_18114">
        <rect
          width="768"
          height="140.488"
          fill="white"
          transform="matrix(1.25 0 0 -1.25 0 176)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Logo
