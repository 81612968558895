import * as React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const variant = {
  primary: {
    text: "#ffffff",
    bg: "#315EFB",
    hover: "#274BC8",
  },
  text: {
    text: "#315EFB",
    bg: "#ffffff",
    hover: "#E8F4F9",
  },
  black: {
    text: "#ffffff",
    bg: "#000000",
    hover: "#000000",
  },
}

const size = {
  default: {
    fontSize: "1em",
  },
  big: {
    fontSize: "1.5em",
  },
}

const Styles = styled.button`
  color: ${props => variant[props.variant].text};
  background-color: ${props => variant[props.variant].bg};
  padding: 1em 1.5em;
  font-size: ${props => size[props.size].fontSize};
  line-height: 1;
  font-weight: 500;
  max-width: 25rem;
  border: none;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.1s ease-out;

  &:hover {
    background-color: ${props => variant[props.variant].hover};
  }

  &:active {
    background-color: ${props => variant[props.variant].hover};
  }
`

const Button = ({ children, ...props }) => {
  return <Styles {...props}>{children}</Styles>
}

Button.defaultProps = {
  variant: "primary",
  size: "default",
}

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
}

export default Button
