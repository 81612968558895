module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-MH5GD8QZJ2"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":["/preview/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"354289554167404"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"id":"business.ibud.ua","name":"ibud.ua для бізнесу","short_name":"ibud.ua business","description":"Спеціалізований маркетплейс у сфері будівництва, ремонту та облаштування дому","scope":"/","lang":"uk","start_url":"/?source=pwa","localize":[{"start_url":"/ru/?source=pwa","lang":"ru","name":"ibud.ua для бизнеса","short_name":"ibud.ua business","description":"Специализированный маркетплейс в сфере строительства, ремонта и обустройства дома"}],"display":"standalone","background_color":"#ffffff","theme_color":"#ffffff","icon":"src/images/icons/icon_1024_maskable.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"92552cf7af9b520d1868955374a4edcd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
